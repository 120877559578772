<template>
    <section>
        <div class="container">
            <TitleBar title="Satınalma Siparişi Kayıtları" :modi="modi" />

            <b-field class="columns box" label="Filtre Alanları" grouped group-multiline>
                <b-field class="column is-2" label="ID">
                    <b-input v-model="purchaseorders.filters.id"></b-input>
                </b-field>
                <b-field class="column is-5" label="Açıklama">
                    <b-input v-model="purchaseorders.filters.name"></b-input>
                </b-field>
                <b-field class="column is-5" label="Tedarikçi Unvan">
                    <CustomerAutofill v-model="purchaseorders.filters.customer" />
                </b-field>
                <b-field class="column is-3" label="Ödeme Tipi">
                    <b-select placeholder="Seçiniz.." v-model="purchaseorders.filters.paymtype" expanded>
                        <option value="Vadeli">Vadeli</option>
                        <option value="Çek">Çek</option>
                        <option value="Peşin">Peşin</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Teslimat Tipi">
                    <b-select placeholder="Seçiniz.." v-model="purchaseorders.filters.shiptype" expanded>
                        <option value="Alıcı Ödemeli">Alıcı Ödemeli</option>
                        <option value="Gönderici Ödemeli">Gönderici Ödemeli</option>
                        <option value="Diğer">Diğer</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Durum">
                    <b-select placeholder="Seçiniz.." v-model="purchaseorders.filters.status" expanded>
                        <option value="Açık">Açık</option>
                        <option value="Askıda">Askıda</option>
                        <option value="Kapalı">Kapalı</option>
                        <option value="İptal">İptal</option>
                    </b-select>
                </b-field>
                <b-field class="column is-3" label="Müşteri Temsilcisi">
                    <UserAutofill v-model="purchaseorders.filters.cusrep" />
                </b-field>
                <b-field class="column is-4" label="Termin Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="purchaseorders.filters.terminat" range> </b-datepicker>
                </b-field>
                <b-field class="column is-4" label="Vade Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="purchaseorders.filters.expiryat" range> </b-datepicker>
                </b-field>
                <b-field class="column is-4" label="Düzenleme Tarihi">
                    <b-datepicker placeholder="Tarih aralığı seçin.." v-model="purchaseorders.filters.issuedat" range> </b-datepicker>
                </b-field>
            </b-field>

            <b-field class="columns box mt-2" label="Satınalma Siparişi Tablosu" grouped group-multiline>
                <b-field class="column is-12" grouped group-multiline>
                    <b-button class="column" type="is-info is-light" icon-left="magnify" label="Ara" @click="purchaseorders_read" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="plus" label="Yeni" @click="purchaseorders_new" />
                    <b-button class="column ml-1" type="is-success is-light" icon-left="content-copy" label="Ref. Al" @click="purchaseorders_ref" disabled />
                    <b-button class="column ml-1" type="is-info is-light" icon-left="eye" label="Görüntüle" :disabled="!purchaseorders.selected.id" @click="purchaseorders_show" />
                    <b-button class="column ml-1" type="is-warning is-light" icon-left="pencil" label="Düzenle" :disabled="!purchaseorders.selected.id" @click="purchaseorders_edit" />
                    <b-button class="column ml-1" type="is-danger is-light" icon-left="delete" label="Sil" :disabled="!purchaseorders.selected.id" @click="purchaseorders_delete" />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="printer" label="Yazdır" @click="purchaseorders_print" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="import" label="Içe Aktar" disabled />
                    <b-button class="column ml-1" type="is-link is-light" icon-left="export" label="Dışa Aktar" @click="purchaseorders_export" disabled />
                </b-field>

                <b-table
                    class="column is-12"
                    :mobile-cards="false"
                    :data="purchaseorders.data"
                    :total="purchaseorders.total"
                    :current-page.sync="purchaseorders.page"
                    :selected.sync="purchaseorders.selected"
                    :sort-multiple-data.sync="purchaseorders.sorts"
                    :loading="$apollo.queries.readPurchaseOrders.loading"
                    @dblclick="purchaseorders_show"
                    @sort="purchaseorders_sort"
                    @sorting-priority-removed="purchaseorders_nosort"
                    backend-pagination
                    backend-sorting
                    sort-multiple
                    striped
                    hoverable
                    narrowed
                    paginated
                    pagination-simple
                >
                    <template slot="empty">
                        <div class="is-12 has-text-centered">
                            Satınalma siparişi kaydı bulunamadı.
                        </div>
                    </template>

                    <b-table-column field="id" label="ID" v-slot="props" sortable>{{ props.row.id.slice(-4) }}</b-table-column>

                    <b-table-column field="name" label="Açıklama" v-slot="props" sortable>{{ props.row.name }}</b-table-column>
                    <b-table-column field="customer.name" label="Tedarikçi Unvan" v-slot="props">{{ props.row.customer.name }}</b-table-column>
                    <b-table-column field="paymtype" label="Ödeme Türü" v-slot="props" sortable>{{ props.row.paymtype }}</b-table-column>
                    <b-table-column field="shiptype" label="Teslimat Türü" v-slot="props" sortable>{{ props.row.shiptype }}</b-table-column>
                    <b-table-column field="terminat" label="Termin Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.terminat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>

                    <b-table-column field="expiryat" label="Vade Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.expiryat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="issuedat" label="Düzenleme Tarihi" v-slot="props" sortable>{{
                        new Date(props.row.issuedat).toLocaleDateString("tr-TR", {
                            weekday: "long",
                            year: "numeric",
                            month: "numeric",
                            day: "numeric"
                        })
                    }}</b-table-column>
                    <b-table-column field="customer.cusrep.name" label="Müşteri Temsilcisi" v-slot="props">{{ props.row.customer.cusrep.name }}</b-table-column>
                    <b-table-column field="status" label="Durum" v-slot="props" sortable>
                        <b-tag :type="statusType(props.row.status)">
                            {{ props.row.status }}
                        </b-tag></b-table-column
                    >
                </b-table>
            </b-field>
        </div>
    </section>
</template>

<script>
import gql from "graphql-tag";
import TitleBar from "@/components/TitleBar.vue";
import UserAutofill from "@/components/UserAutofill.vue";
import CustomerAutofill from "@/components/CustomerAutofill.vue";

export default {
    name: "PurchaseOrderTable",
    components: {
        TitleBar,
        UserAutofill,
        CustomerAutofill
    },
    data: () => ({
        modi: "",
        purchaseorders: {
            page: 1,
            take: 20,
            total: 9999,
            data: [],
            sorts: [],
            filters: {
                cusrep: {},
                customer: {},
                terminat: [],
                expiryat: [],
                issuedat: []
            },
            selected: {}
        }
    }),
    computed: {
        statusType() {
            return (status) =>
                status == "Açık"
                    ? "is-danger is-light"
                    : status == "Askıda"
                    ? "is-warning is-light"
                    : status == "Kapalı"
                    ? "is-success is-light"
                    : status == "İptal"
                    ? "is-light"
                    : "is-light";
        }
    },
    mounted() {
        this.purchaseorders_read();
    },
    methods: {
        purchaseorders_read() {
            this.$apollo.queries.readPurchaseOrders.start();
            this.$apollo.queries.readPurchaseOrders.refetch();
        },
        purchaseorders_new() {
            this.$router.push({ path: "/satinalma/siparis/ekle" });
        },
        purchaseorders_ref() {
            this.$router.push({
                path: "/satinalma/siparis/ekle",
                query: { id: this.purchaseorders.selected.id, modi: "ref" }
            });
        },
        purchaseorders_show() {
            this.$router.push({
                path: "/satinalma/siparis/detay",
                query: { id: this.purchaseorders.selected.id, modi: "show" }
            });
        },
        purchaseorders_edit() {
            this.$router.push({
                path: "/satinalma/siparis/detay",
                query: { id: this.purchaseorders.selected.id, modi: "edit" }
            });
        },
        purchaseorders_delete() {},
        purchaseorders_print() {},
        purchaseorders_export() {},
        purchaseorders_sort(field, order) {
            this.purchaseorders_nosort(field);
            this.purchaseorders.sorts.push({ field, order });
        },
        purchaseorders_nosort(field) {
            this.purchaseorders.sorts.forEach((e, i, a) => {
                if (e.field == field) a.splice(i, 1);
            });
        }
    },
    apollo: {
        readPurchaseOrders: {
            query: gql`
                query readPurchaseOrders($config: String) {
                    readPurchaseOrders(config: $config) {
                        id
                        name
                        customer {
                            id
                            name
                            cusrep {
                                id
                                name
                            }
                        }
                        status
                        paymtype
                        shiptype
                        terminat
                        expiryat
                        issuedat
                    }
                }
            `,
            variables() {
                var sorts = [];
                this.purchaseorders.sorts.forEach((e) => sorts.push({ column: e.field, order: e.order }));
                for (var filter in this.purchaseorders.filters) if (!this.purchaseorders.filters[filter]) delete this.purchaseorders.filters[filter];

                return {
                    config: JSON.stringify({
                        sorts,
                        take: this.purchaseorders.take,
                        filters: this.purchaseorders.filters,
                        skip: (this.purchaseorders.page - 1) * this.purchaseorders.take
                    })
                };
            },
            update(data) {
                this.purchaseorders.data = data.readPurchaseOrders;
            }
        }
    }
};
</script>
